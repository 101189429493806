<template>
  <div class="editor" v-if="editor">
    <editor-content class="editor__content" :editor="editor" />
    <menu-bar class="editor__header" :editor="editor" />
  </div>
</template>

<script>
// import { HocuspocusProvider } from '@hocuspocus/provider'
// import CharacterCount from '@tiptap/extension-character-count'
// import Collaboration from '@tiptap/extension-collaboration'
// import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import Underline from '@tiptap/extension-underline'
import Highlight from '@tiptap/extension-highlight'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-2'
// import * as Y from 'yjs'
import Mention from '@tiptap/extension-mention'
import suggestion from './suggestion'

import MenuBar from './MenuBar.vue'

// const getRandomElement = list => {
//   return list[Math.floor(Math.random() * list.length)]
// }

// const getRandomRoom = () => {
//   return getRandomElement(['rooms.10', 'rooms.11', 'rooms.12'])
// }
import { mapActions } from 'vuex'

export default {
  components: {
    EditorContent,
    MenuBar
  },
  props: {
    value: {
      required: false
    },
    resetNote: {
      required: false
    },
    modal: {
      required: false
    }
  },
  data() {
    return {
      // currentUser: JSON.parse(localStorage.getItem('currentUser')) || {
      //   name: this.getRandomName(),
      //   color: this.getRandomColor()
      // },
      valueContent: ``,
      // provider: null,
      editor: null
      // status: 'connecting',
      // room: getRandomRoom()
    }
  },

  async mounted() {
    if (this.modal != true) {
      this.valueContent = ``
    } else {
      this.valueContent = this.value
    }

    await this.fetchUsersComments()
    // const ydoc = new Y.Doc()

    // this.provider = new HocuspocusProvider({
    //   url: 'wss://connect.hocuspocus.cloud',
    //   parameters: {
    //     key: 'write_bqgvQ3Zwl34V4Nxt43zR'
    //   },
    //   name: this.room,
    //   document: ydoc
    // })

    // this.provider.on('status', event => {
    //   this.status = event.status
    // })

    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          history: true
        }),
        Highlight,
        TaskList,
        TaskItem,
        Underline,
        // Collaboration.configure({
        //   document: ydoc
        // }),
        // CollaborationCursor.configure({
        //   provider: this.provider,
        //   user: this.currentUser
        // }),
        // CharacterCount.configure({
        //   limit: 10000
        // }),
        Mention.configure({
          HTMLAttributes: {
            class: 'mention'
          },
          suggestion
        })
      ],
      content: this.valueContent,
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML())
        localStorage.setItem(
          'content',
          JSON.stringify(this.editor.getHTML().replace(/<[^>]+>/g, ''))
        )
      }
    })
  },

  methods: {
    ...mapActions(['fetchUsersComments'])

    // setName() {
    //   const name = (window.prompt('Name') || '').trim().substring(0, 32)
    //   if (name) {
    //     return this.updateCurrentUser({
    //       name
    //     })
    //   }
    // },

    // updateCurrentUser(attributes) {
    //   this.currentUser = { ...this.currentUser, ...attributes }
    //   this.editor
    //     .chain()
    //     .focus()
    //     .updateUser(this.currentUser)
    //     .run()

    //   localStorage.setItem('currentUser', JSON.stringify(this.currentUser))
    // },

    // getRandomColor() {
    //   return getRandomElement([
    //     '#958DF1',
    //     '#F98181',
    //     '#FBBC88',
    //     '#FAF594',
    //     '#70CFF8',
    //     '#94FADB',
    //     '#B9F18D'
    //   ])
    // },

    // getRandomName() {
    //   return getRandomElement([
    //     // 'Lea Thompson',
    //     // 'Cyndi Lauper',
    //     // 'Tom Cruise',
    //     // 'Madonna',
    //     // 'Jerry Hall',
    //     // 'Joan Collins',
    //     // 'Winona Ryder',
    //     // 'Christina Applegate',
    //     // 'Alyssa Milano',
    //     // 'Molly Ringwald',
    //     // 'Ally Sheedy',
    //     // 'Debbie Harry',
    //     // 'Olivia Newton-John',
    //     // 'Elton John',
    //     // 'Michael J. Fox',
    //     // 'Axl Rose',
    //     // 'Emilio Estevez',
    //     // 'Ralph Macchio',
    //     // 'Rob Lowe',
    //     // 'Jennifer Grey',
    //     // 'Mickey Rourke',
    //     // 'John Cusack',
    //     // 'Matthew Broderick',
    //     // 'Justine Bateman',
    //     // 'Lisa Bonet'
    //   ])
    // }
  },
  watch: {
    resetNote() {
      this.editor.commands.clearContent()
    }
  },
  beforeUnmount() {
    this.editor.destroy()
    // this.provider.destroy()
  }
}
</script>
<style lang="scss" scoped>
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }
}

.mention {
  border: 1px solid #000;
  border-radius: 0.4rem;
  padding: 0.1rem 0.3rem;
  box-decoration-break: clone;
}
</style>
<style lang="scss" scoped>
.editor {
  display: flex;
  flex-direction: column;
  max-height: 26rem;
  color: #0d0d0d;
  border: 1px solid #e9eab3;
  background-color: #fbfdc2;
  // border-radius: 0.75rem;
  margin: 3px 3px 3px 3px;
  &__header {
    border-bottom: 0px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding: 2px;
    // border-bottom: 3px solid #0d0d0d;
  }

  &__content {
    padding: 1.25rem 1rem;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__footer {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    white-space: nowrap;
    border-top: 3px solid #0d0d0d;
    font-size: 12px;
    font-weight: 600;
    color: #0d0d0d;
    white-space: nowrap;
    padding: 0.25rem 0.75rem;
  }

  /* Some information about the status */
  &__status {
    display: flex;
    align-items: center;
    border-radius: 5px;

    &::before {
      content: ' ';
      flex: 0 0 auto;
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      background: rgba(#0d0d0d, 0.5);
      border-radius: 50%;
      margin-right: 0.5rem;
    }

    &--connecting::before {
      background: #616161;
    }

    &--connected::before {
      background: #b9f18d;
    }
  }

  &__name {
    button {
      background: none;
      border: none;
      font: inherit;
      font-size: 12px;
      font-weight: 600;
      color: #0d0d0d;
      border-radius: 0.4rem;
      padding: 0.25rem 0.5rem;

      &:hover {
        color: #fff;
        background-color: #0d0d0d;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
/* Give a remote user a caret */
.collaboration-cursor__caret {
  position: relative;
  margin-left: -1px;
  margin-right: -1px;
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  word-break: normal;
  pointer-events: none;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  position: absolute;
  top: -1.4em;
  left: -1px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  user-select: none;
  color: #0d0d0d;
  padding: 0.1rem 0.3rem;
  border-radius: 3px 3px 3px 0;
  white-space: nowrap;
}

/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  mark {
    background-color: #faf594;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  hr {
    margin: 1rem 0;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  ul[data-type='taskList'] {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
      }

      > div {
        flex: 1 1 auto;
      }
    }
  }
}
</style>
