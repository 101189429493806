<template>
  <div class="items" ref="scrollItems">
    <template v-if="items && items.length">
      <!-- :class="{ 'is-selected': index === selectedIndex }" -->
      <button
        class="item is-selected"
        v-for="(item, index) in items"
        :key="index"
        @click="selectItem(index)"
      >
        {{ item }}
      </button>
    </template>
    <div class="item" v-else>
      No result
    </div>
  </div>
</template>

<script>
// import store from '@/store'

export default {
  props: {
    items: {
      type: Array,
      required: true
    },

    command: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedIndex: 0
    }
  },

  watch: {
    items() {
      this.selectedIndex = 0
    }
  },

  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.$refs.scrollItems.scrollTop =
          this.$refs.scrollItems.clientHeight * this.selectedIndex
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.$refs.scrollItems.scrollTop =
          this.$refs.scrollItems.clientHeight * this.selectedIndex
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },

    upHandler() {
      this.selectedIndex =
        (this.selectedIndex + this.items.length - 1) % this.items.length
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
    },

    enterHandler() {
      this.selectItem(this.selectedIndex)
    },

    selectItem(index) {
      const item = this.items[index]
      // if (store.state && store.state.users && store.state.users.length) {
      //   let users = store.state.users.filter(user => user.full_name === item)
      //   // this.$emit('mentionUsersId', users)
      // }
      if (item) {
        this.command({ id: item })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.items {
  padding: 0.2rem;
  position: relative;
  border-radius: 0.5rem;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  font-size: 0.9rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
}
.items::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}
.items::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #80808054;
  border-radius: 10px;
}
.items::-webkit-scrollbar-thumb {
  background: #dcdee0;
  border-radius: 10px;
}
.items::-webkit-scrollbar-thumb:hover {
  background: #888;
}
.items {
  white-space: normal;
  max-height: 152px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 250px;
  // margin-right: 2px;
  margin-top: 2px;
  // margin-left: -38px;
  margin-right: -83px;
  padding: 3px 4px;
}
.item {
  display: block;
  margin: 0;
  width: 100%;
  text-align: left;
  background: transparent;
  border-radius: 0.4rem;
  border: 1px solid transparent;
  padding: 0.2rem 0.4rem;

  &.is-selected {
    &:hover {
      border-color: #000;
    }
  }
}
</style>
